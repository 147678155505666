import React from 'react'
import {Link} from 'react-router-dom'
import icon from '../../assets/img/cart.svg'
import {connect} from 'react-redux'

class CartIcon extends React.Component {

  amount = qty => {
    if(!qty) return <></>
    if(!qty > 0) return <></>
    return <span>{qty}</span>
  }

  render(){
    const {totalQuantity} = this.props.cartState

    return (
      <Link to="/cart" className='_cart-icon'>
        <img src={icon} alt="Cart" />
        {this.amount(totalQuantity)}
      </Link>
    )
  }
}

const mapStateToProps = state => {
  return {
      cartState: state.cartState
  }
}

export default connect(mapStateToProps)(CartIcon)