import React, {createRef} from 'react'
import {
  getCoupon,
  removeCoupon
} from '../../actions/coupon'
import {
  resetForms
} from '../../actions'
import {connect} from 'react-redux'

class CouponField extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      value: this.props.coupon ? this.props.coupon.code : '',
    }
  }

  componentWillUnmount(){
    this.props.resetForms()
  }

  renderErrors = () => {
    if(!this.props.errors){
      return <></>
    }

    if(typeof this.props.errors === 'string'){
      return (
          <div className="_error-group">
              <div className="_error-message">{this.props.errors}</div>
          </div>
      )
    }

    return Object.values(this.props.errors).map((item, index) => {                
        return (
            <div className="_error-group" key={index}>
                <div className="_error-message">{item[0]}</div>
            </div>
        )
    })
}

  onChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  removeCoupon = () => {
    setTimeout(() => {
      this.props.removeCoupon()
      this.setState({value: ''})
    }, 300);
  }

  onSubmit = () => {
    this.props.getCoupon(this.state.value)
  }

  render(){
    return(
      <div className='_coupon-form' >
        <input type="text" placeholder="Enter your coupon code or gift card" value={this.state.value} onChange={e => this.onChange(e)}  />
        {this.props.coupon && <button onClick={this.removeCoupon}>Remove Coupon</button>}
        {!this.props.coupon && <button onClick={this.onSubmit}>Apply</button>}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    coupon: state.couponState.details,
    success: state.couponState.success,
    errors: state.couponState.errors
  }
} 

export default connect(mapStateToProps, {
  getCoupon,
  removeCoupon,
  resetForms
})(CouponField)