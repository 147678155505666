import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input
} from './fields'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Recaptcha from 'react-recaptcha'

class ContactForm extends React.Component {

    state= {
        button: 'Send',
        message: '',
        capchaLoaded: null,
        capchaVerified: null
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({
            capchaLoaded: true
        })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({
            capchaVerified: true
        })
    }

    renderErrors = () => {
        if(this.props.errors){

            if(typeof this.props.errors === 'string'){
                return (
                    <div className="_error-group">
                        <div className="_error-message">{this.props.errors}</div>
                    </div>
                )
            }
            
            return Object.values(this.props.errors).map((item, index) => {                
                return (
                    <div className="_error-group">
                        <div className="_error-message" key={index}>{item[0]}</div>
                    </div>
                )
            })
        }
    }

    onSubmit = formValues => {
        if(this.state.capchaLoaded){
            if(this.state.capchaVerified){
                this.setState({
                    button: 'Sending'
                })
                const data = new FormData()
                if(formValues.firstname){ data.append('firstname', formValues.firstname) }
                if(formValues.lastname){ data.append('lastname', formValues.lastname) }
                if(formValues.email){ data.append('email', formValues.email) }
                if(formValues.phone){ data.append('phone', formValues.phone) }
                if(formValues.country){ data.append('country', formValues.country) }
                data.append('message', this.state.message)
                this.props.onSubmit(data)

            } else {
                console.log('Robot filter denied')
            }
        }
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form className="_form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row"> 
                    <Field 
                        type="text" 
                        name="firstname" 
                        label="First Name*" 
                        component={Input}
                    />
                    <Field 
                        type="text" 
                        name="lastname" 
                        label="Last Name*" 
                        component={Input}
                    />
                </div>
                <div className="_form-row">
                    <Field 
                        type="email" 
                        name="email" 
                        label="Email Address*" 
                        component={Input}
                    />
                    <Field
                        type="text"
                        name="phone"
                        label="Phone Number*"
                        component={Input}
                    />
                </div>
                <div className="_form-row">
                    <Field
                        type="text"
                        name="country"
                        label="Country*"
                        component={Input}
                    />
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Message*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.message}
                            onChange={ ( event, editor ) => {
                                const message = editor.getData();
                                this.setState({ message })
                            } }
                        />
                    </div>
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Lf25pAfAAAAAGjQv-ug0i8FQTMH7451quTi_sV7"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                <br />
                <div className="_form-row">
                    <button className={`_button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>{this.state.button}</button>
                </div>
                {this.renderErrors()}
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.firstname){
        errors.firstname = "you must enter your first name"
    }

    if(!formValues.lastname){
        errors.lastname = "you must enter your last name"
    }

    if(!formValues.email){
        errors.email = "you must enter your email address"
    }

    if(!formValues.country){
        errors.country = "you must enter your country"
    }

    if(!formValues.phone){
        errors.phone = "you must enter your phone number"
    }

    if(!formValues.message){
        errors.message = "you must enter a message"
    }

    return errors
}

export default reduxForm({
    form: 'contact',
    validate
})(ContactForm)