import React from 'react'
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import ScrolledIntoView from '../../ScrolledIntoView'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {BASE_PATH} from '../../paths'

class SplitColumnCarouselSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    scrollToPosition = () => {
        window.scrollTo({
            top: 0, // scroll so that the element is at the top of the view
            // behavior: 'smooth' // smooth scroll
        })   
    }

    renderLeftColumnItems = () => {
        return this.props.slidesData.map(({image2, title, slug}, index) => {
            return(
                <div key={index}>
                    <div className="_slider-item-container">
                        <img src={BASE_PATH + image2} alt={title} />
                        <h2>{title}</h2>
                        <Link to={`/rugs/${slug}`} className="_button" onClick={() => this.scrollToPosition()}>View</Link>
                    </div>                    
                </div>
            )
        })
    }

    renderRightColumnItems = () => {
        
        return this.props.slidesData.map(({image}, index) => {
            return(
                <div key={index}>
                    <div className="_slider_item-img" style={{
                        backgroundImage: `url(${BASE_PATH + image})`
                    }} />
                </div>
            )
        })
    }

    render(){

        return(
            <section className="_section _section-template-3">
                <div className="_fluid">
                    <div className="_column">
                        <Slider
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            slidesToShow={1}
                            arrows={false}
                            dots={false}
                            autoplay={true}
                            autoplaySpeed={5000}
                            fade={true}
                            speed={1000}
                            easing="ease-in-out"
                        >
                            {this.renderRightColumnItems()}
                        </Slider>
                    </div>
                    <div className="_column">
                        <ScrolledIntoView>
                            <div className="_panel">
                                <h3>Our Selection of Rugs</h3>
                                <Slider
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}
                                    slidesToShow={1}
                                    infinite={true}
                                    arrows={false}
                                    dots={true}
                                    autoplay={true}
                                    autoplaySpeed={5000}
                                    easing="ease-in-out"
                                    speed={1000}
                                >
                                    {this.renderLeftColumnItems()}
                                </Slider>
                            </div>
                        </ScrolledIntoView>
                    </div>                    
                </div>
            </section>
        )
    }    
}

export default SplitColumnCarouselSection