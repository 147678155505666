import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import ShopBanner from '../ui/ShopBanner'
import ShopList from './ShopList'
import {
  fetchPublicShopCategory,
  fetchPublicShopProducts
} from '../../actions/public-shop'
import {connect} from 'react-redux'

class ShopCategoryPage extends React.Component{

  componentDidMount(){
    this.props.fetchPublicShopCategory(this.props.match.params.categorySlug)
    this.props.fetchPublicShopProducts(this.props.match.params.categorySlug)
  }

    render(){

        if(!this.props.category)
        return <></>

        const {name, description, slug} = this.props.category

        return(
            <>
            <ScrollToTop />
            <Seo 
                title={`${name} | Shop | Coral & Hive - Natural Handwoven Rugs`}
                description={description}
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/">Home</Link><Divider /><Link to="/shop">Shop</Link><Divider />{name}
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <ShopBanner>
                  <h1>{name}</h1>
                  <div dangerouslySetInnerHTML={{__html: description}} />
                </ShopBanner>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <ShopList data={this.props.products} categorySlug={slug} />
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    category: state.publicShopCategoriesState[ownProps.match.params.categorySlug],
    products: Object.values(state.publicShopProductsState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicShopCategory,
  fetchPublicShopProducts
})(ShopCategoryPage)