import React from 'react'

class TemplateBlogArticle extends React.Component{

    render(){

        return (
            <section className={`_section _section-template-blog ${this.props.className ? this.props.className : ''}`}>
                <div className={`${this.props.fluid ? '_fluid' : '_box'}`}>
                    <div className="_column">
                        {this.props.children}
                    </div>
                </div>
            </section>
        )
    }
}

export default TemplateBlogArticle