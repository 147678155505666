import _ from 'lodash'
import {
    FETCH_PRODUCTS,
    CREATE_PRODUCT,
    FETCH_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    RESTORE_PRODUCT
} from '../actions/types'

const initialState = {}

const productsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRODUCTS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_PRODUCT:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_PRODUCT:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_PRODUCT:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_PRODUCT:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_PRODUCT:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default productsReducer