import React from 'react'
import ShopItem from './ShopItem'

class ShopList extends React.Component {

  renderItems = () => {

    return this.props.data.map((item, index) => {
      return (
        <ShopItem key={index} data={item}/>
      )
    })
  }
  
  render(){

    return (
      <section className='_section _section-shop-list'>
        <div className='_grid _grid-4'>
          {this.renderItems()}
        </div>
        {this.props.colorBox && <div className='_color-box' />}
      </section>
    )
  }
}

export default ShopList