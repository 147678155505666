const Breadcrums = props => {
  return (
    <section className='_section _section-breadcrums'>
      <div className='_grid'>
        <div className='_column'>
          <div className='_public-breadcrums'>
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Breadcrums