import _ from 'lodash'
import {
    FETCH_PRODUCT_GALLERY_BIN,
    RESTORE_PRODUCT_GALLERY_IMAGE,
    FORCE_DELETE_PRODUCT_GALLERY_IMAGE
} from '../actions/types'

const initialState = {}

const productGalleryBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRODUCT_GALLERY_BIN:
            return {
                ...state, [action.id]: {..._.mapKeys(action.payload, 'id')}
            }  
        case RESTORE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: _.omit(state[action.id], action.payload.id)
            }
        case FORCE_DELETE_PRODUCT_GALLERY_IMAGE:
            return {
                ...state, [action.id]: _.omit(state[action.id], action.image_id)
            }
        default:
            return state
    }
}

export default productGalleryBinReducer