import React from 'react'
import ThemeHeading from './ThemeHeading'
import {Link} from 'react-router-dom'
// import Pagination from './Pagination'
import {BASE_PATH} from '../../paths'
import ScrolledIntoView from '../../ScrolledIntoView'

class CardsSection extends React.Component {

    renderCards = () => {
        return this.props.data.map(({image, headline, summary, slug}, index) => {
            return ( 
                <ScrolledIntoView key={index}>            
                    <Link to={`/blog/${slug}`} className="_card">
                        <ThemeHeading>
                            <h2>{headline}</h2>
                        </ThemeHeading>
                        <p>{summary}</p>
                        <figure>
                            <img src={BASE_PATH + image} alt={headline} />
                        </figure>
                    </Link>
                </ScrolledIntoView>   
            )
        })
    }

    render(){
        return(           
            <section className="_section _section-template-1">
                <div className="_box">
                    <div className="_inner">
                        <div className="container _masonary-grid-cards">
                            {this.renderCards()}
                        </div>
                    </div>    
                    {/* <Pagination data={this.props.data} links={this.props.links} meta={this.props.meta} />                     */}
                </div>
            </section>
            
            

        )
    }
}

export default CardsSection