import React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SimpleCarousel extends React.Component {

    render() {
        const settings = {
          dots: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,
        };
        return (
          <div>
            <Slider {...settings}>
                {this.props.children}
            </Slider>
          </div>
        );
      }
}

export default SimpleCarousel