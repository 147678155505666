import React from 'react'
import Layout from '../layout/Layout'
import NewsletterSubscriptionComponent from '../ui/NewsletterSubscription'


class NewsletterSubscription extends React.Component{

    render(){

        return(
            <Layout transparent={false}>
                <NewsletterSubscriptionComponent />
            </Layout>
        )
    }
}

export default NewsletterSubscription