import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select,
    TextArea,
    Checkbox2
} from '../forms/fields'
import scrollToFirstError from '../forms/SmoothScrollToErrorFields.js'
import {Link} from 'react-router-dom'
import {formatPrice} from '../../utils'
import Recaptcha from 'react-recaptcha'
import history from '../../history'

class checkoutForm extends React.Component {

    state= {
        capchaLoaded: null,
        capchaVerified: null,
        country: "United Kingdom",
        region: null,
        createAccount: false,
        shippingAddress: false
    }

    toggleCreateAccount = () => {
        this.setState({createAccount: !this.state.createAccount})
    }

    toggleShippingAddress = () => {
        this.setState({shippingAddress: !this.state.shippingAddress})
    }

    renderCartItems = () => {

        const cartItems = Object.values(this.props.cart.items)
  
        return cartItems.map(({id,name,total}) => {
            return (
                <tr key={id}>
                    <td>{name}</td>
                    <td>£ {formatPrice(total)}</td>
                </tr>
            )
        })
      }

    setCountry = e => {
        this.setState({country: e.target.value})
    }

    setRegion = e => {
        this.setState({region: e.target.value})
    }

    renderRegions = type => {

        switch (this.state.country) {
            case 'United Kingdom':
                return (
                    <Field 
                        name={`${type ? type : 'billing'}_region`} 
                        label="Region*" 
                        component={Select} 
                        // onChange={this.setRegion}
                    >
                        <option value="">Please select a region</option>
                        <option>England</option>
                        <option>Scotland</option>
                        <option>Wales</option>
                        <option>Northern Ireland</option>
                    </Field>
                )
            case 'USA':
                return (
                    <Field 
                        name={`${type ? type : 'billing'}_region`} 
                        label="State*" 
                        component={Select} 
                        // onChange={this.setRegion}
                    >
                        <option value="">Please select a state</option>
                        <option>Alabama</option>
                        <option>Alaska</option>
                        <option>Arizona</option>
                        <option>Arkansas</option>
                        <option>California</option>
                        <option>Colorado</option>
                        <option>Connecticut</option>
                        <option>Delaware</option>
                        <option>Florida</option>
                        <option>Georgia</option>
                        <option>Hawaii</option>
                        <option>Idaho</option>
                        <option>Illinois</option>
                        <option>Indiana</option>
                        <option>Iowa</option>
                        <option>Kansas</option>
                        <option>Kentucky</option>
                        <option>Louisiana</option>
                        <option>Maine</option>
                        <option>Maryland</option>
                        <option>Massachusetts</option>
                        <option>Michigan</option>
                        <option>Minnesota</option>
                        <option>Mississippi</option>
                        <option>Missouri</option>
                        <option>Montana</option>
                        <option>Nebraska</option>
                        <option>Nevada</option>
                        <option>New Hampshire</option> 
                        <option>New Jersey</option> 
                        <option>New Mexico</option> 
                        <option>New York</option> 
                        <option>North Carolina</option> 
                        <option>North Dakota</option> 
                        <option>Ohio</option>
                        <option>Oklahoma</option>
                        <option>Oregon</option>
                        <option>Pennsylvania</option>
                        <option>Rhode Island</option> 
                        <option>South Carolina</option> 
                        <option>South Dakota</option> 
                        <option>Tennessee</option>
                        <option>Texas</option>
                        <option>Utah</option>
                        <option>Vermont</option>
                        <option>Virginia</option>
                        <option>Washington</option>
                        <option>West Virginia</option> 
                        <option>Wisconsin</option>
                        <option>Wyoming</option>
                    </Field>
                )
            case 'South Africa':
                return (
                    <Field 
                        name={`${type ? type : 'billing'}_region`}  
                        label="Province*" 
                        component={Select} 
                        // onChange={this.setRegion}
                    >
                        <option value="">Please select a province</option>
                        <option>Western Cape</option>
                        <option>Garden Route</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                    </Field>
                    )
            default:
                return (
                    <Field name="billing_region" type="text" component={Input} label="Enter region or area*" />
                )
        }
        
    }

    renderErrors = () => {
        if(!this.props.errors){
            return <></>
        }

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {   
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {

        console.log(formValues)

        if(!this.state.capchaLoaded)
        return

        if(!this.state.capchaVerified){
            console.log('Robot filter denied')
            return
        }

        const {discount,deliveryCost,subTotalCost,totalCost} = this.props.cart

        const data = new FormData()
        
        // billing
        formValues.name && data.append('billing_name', formValues.name)
        formValues.surname && data.append('billing_surname', formValues.surname)
        formValues.email && data.append('billing_email', formValues.email)
        formValues.billing_phone && data.append('billing_phone', formValues.billing_phone)
        this.state.country && data.append('billing_country', this.state.country)
        formValues.billing_region && data.append('billing_region', formValues.billing_region)
        formValues.billing_city && data.append('billing_city', formValues.billing_city)
        formValues.billing_street_address && data.append('billing_address', formValues.billing_street_address)
        formValues.billing_postal_code && data.append('billing_postal_code', formValues.billing_postal_code)

        // shipping
        formValues.ship_to_differnet_address === true && data.append('ship_to_differnet_address', formValues.ship_to_differnet_address)
        formValues.shipping_name && data.append('shipping_name', formValues.shipping_name)
        formValues.shipping_surname && data.append('shipping_surname', formValues.shipping_surname)
        formValues.shipping_country && data.append('shipping_country', formValues.shipping_country)
        formValues.shipping_region && data.append('shipping_region', formValues.shipping_region)
        formValues.shipping_city && data.append('shipping_city', formValues.shipping_city)
        formValues.shipping_street_address && data.append('shipping_street_address', formValues.shipping_street_address)
        formValues.shipping_postal_code && data.append('shipping_postal_code', formValues.shipping_postal_code)

        formValues.create_account === true && data.append('create_account', formValues.create_account)
        formValues.opt_in && data.append('opt_in', formValues.opt_in)
        formValues.password && data.append('password', formValues.password)
        formValues.password_confirmation && data.append('password_confirmation', formValues.password_confirmation)
        formValues.comments && data.append('comments', formValues.comments)
        data.append('subtotal', formatPrice(subTotalCost))
        data.append('freight', formatPrice(deliveryCost))
        data.append('discount', formatPrice(discount))
        data.append('total', formatPrice(totalCost))
        data.append('cart_items', JSON.stringify(this.props.cart.items))
        this.props.cart.coupon && data.append('coupon', this.props.cart.coupon.code)
        this.props.orderId && data.append('orderId', this.props.orderId)
        formValues.paymentMethod && data.append('paymentMethod', formValues.paymentMethod)
        this.props.onSubmit(data)
    }

    render(){

        if(!Object.values(this.props.cart.items).length > 0){
            history.push('/cart')
        }

        const {discount,deliveryCost,subTotalCost,totalCost} = this.props.cart

        return(
            <section className='_section _section-checkout'>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_grid _grid-2">
                    <div className='_column'>
                        <h3>Billing Details</h3>
                        {'name' && <div name={`position-name`} />}
                        {'surname' && <div name={`position-surname`} />}
                        <div className="_form-row">
                            <Field name="name" type="text" component={Input} label="First Name*" />
                            <Field name="surname" type="text" component={Input} label="Last Name*" />
                        </div>
                        {'email' && <div name={`position-email`} />}
                        {'billing_phone' && <div name={`position-billing_phone`} />}
                        <div className="_form-row">
                            <Field name="email" type="email" component={Input} label="Email Address*" />
                            <Field name="billing_phone" type="text" component={Input} label="Phone Number*" />
                        </div>

                        {'billing_country' && <div name={`position-billing_country`} />}
                        {'billing_region' && <div name={`position-billing_region`} />}
                        <div className="_form-row">
                            {this.state.shippingAddress && (
                                <>
                                    <Field name="billing_country" type="text" component={Input} label="Country*" />
                                    <Field name="billing_region" type="text" component={Input} label="Region*" />
                                </>
                            )}
                            {!this.state.shippingAddress && (
                                <>
                                    <Field 
                                        name="billing_country"
                                        label="Country*" 
                                        component={Select} 
                                        onChange={this.setCountry}
                                    >
                                        <option value="">Please select a country</option>
                                        <option>United Kingdom</option>
                                    </Field>
                                    {this.renderRegions()}
                                </>
                            )}
                            
                            
                        </div>

                        
                        {'billing_city' && <div name={`position-billing_city`} />}
                        {'billing_postal_code' && <div name={`position-billing_postal_code`} />}
                        <div className="_form-row">                            
                            <Field 
                                type="text"
                                name="billing_city" 
                                label="City / Town*" 
                                component={Input} 
                            />
                            <Field name="billing_postal_code" type="text" component={Input} label="Postcode*" />
                        </div>
                        
                        {'billing_street_address' && <div name={`position-billing_street_address`} />}
                        <div className="_form-row">                            
                            <Field name="billing_street_address" type="text" component={Input} label="Address*" />
                        </div>

                        <div className="_form-row">
                            <Field name="ship_to_differnet_address" component={Checkbox2} label="Ship to a different address?"  onChange={this.toggleShippingAddress} />
                        </div>

                        {this.state.shippingAddress && (
                            <>
                                <h3>Shipping Details</h3>
                                {'shipping_name' && <div name={`position-shipping_name`} />}
                                {'shipping_surname' && <div name={`position-shipping_surname`} />}
                                <div className="_form-row">
                                    <Field name="shipping_name" type="text" component={Input} label="First Name*" />
                                    <Field name="shipping_surname" type="text" component={Input} label="Last Name*" />
                                </div>

                                {'shipping_country' && <div name={`position-shipping_country`} />}
                                {'shipping_region' && <div name={`position-shipping_region`} />}
                                <div className="_form-row">
                                    <Field 
                                        name="shipping_country"
                                        label="Country*" 
                                        component={Select} 
                                        onChange={this.setCountry}
                                    >
                                        <option value="">Please select a country</option>
                                        <option>United Kingdom</option>
                                    </Field>
                                    {this.renderRegions('shipping')}
                                </div>

                                
                                {'shipping_city' && <div name={`position-shipping_city`} />}
                                {'shipping_postal_code' && <div name={`position-shipping_postal_code`} />}
                                <div className="_form-row">                            
                                    <Field 
                                        type="text"
                                        name="shipping_city" 
                                        label="City / Town*" 
                                        component={Input} 
                                    />
                                    <Field name="shipping_postal_code" type="text" component={Input} label="Postcode*" />
                                </div>
                                
                                {'shipping_street_address' && <div name={`position-shipping_street_address`} />}
                                <div className="_form-row">                            
                                    <Field name="shipping_street_address" type="text" component={Input} label="Address*" />
                                </div>
                            </>
                        )}

                        <div className="_form-row">
                            <Field
                                name="comments" 
                                label="Comments" 
                                component={TextArea} 
                            />
                        </div>

                        <div className="_form-row">
                            <Field name="opt_in" component={Checkbox2} label="I want to receive exclusive news from Coral & Hive" />
                        </div>

                        {
                            !this.props.auth.token && (
                                <>
                                    <br />
                                    <div className="_form-row">
                                        <Field name="create_account" component={Checkbox2} label="Create Account" onChange={this.toggleCreateAccount} />
                                    </div>
                                    
                                    {
                                        this.state.createAccount && (
                                            <div className={`_create-account`}>
                                                <div className="_form-row">
                                                    <Field name="password" type="password" component={Input} label="Create Account Password*" />
                                                </div>
                                                <div className="_form-row">
                                                    <Field name="password_confirmation" type="password" component={Input} label="Confirm Password*" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <p>Already have an account? <Link to="/my-account" style={{textDecoration: 'underline'}}>Log in</Link></p>
                                </>
                            )
                        }
                        
                    </div>
                    <div className='_column '>
                        <div className='_light-background'>
                            <h4>Your Order</h4>
                            <table className='_checkout-table'>
                                <thead>
                                    <tr>
                                        <th>PRODUCT</th>
                                        <th>SUBTOTAL</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className='_horline' />
                            <table className='_checkout-table-cart-items'>
                                <tbody>
                                    {this.renderCartItems()}
                                </tbody>
                            </table>
                            <div className='_horline' />
                            <table className='_checkout-table-totals'>
                                <tbody>
                                    <tr>
                                    <td>Subtotal</td>
                                    <td>£ {formatPrice(subTotalCost)}</td>
                                </tr>
                                <tr>
                                    <td>Discount</td>
                                    <td>£ {formatPrice(discount)}</td>
                                </tr>
                                <tr>
                                    <td>Delivery (Flat Rate)</td>
                                    <td>£ {formatPrice(deliveryCost)}</td>
                                </tr>
                                <tr className='_total'>
                                    <td>Total</td>
                                    <td>£ {formatPrice(totalCost)}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className='_horline' style={{margin: '30px 0'}} />
                            <div className="_form-row">
                                <div className="_form-group">
                                    <label>Please verify that you are not a robot*</label>
                                    <Recaptcha
                                        sitekey="6Lf25pAfAAAAAGjQv-ug0i8FQTMH7451quTi_sV7"
                                        render="explicit"
                                        onloadCallback={this.callback}
                                        verifyCallback={this.verifyCallback}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="_form-group _place-order">
                                <button className="_button _checkout-submit">PLACE ORDER</button>
                            </div>
                            {this.renderErrors()}
                            <p className='_terms-messsage'>By placing your order you agree to our <Link to="/terms-and-conditions">Terms & Conditions</Link>, <Link to="/privacy-policy">Privacy Policy</Link></p>
                        </div>
                    </div>
                </form>
                
            </section>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your first name"
    }
    if(!formValues.surname){
        errors.surname = "You must enter your last name"
    }
    if(!formValues.email){
        errors.email = "You must enter your email address"
    }
    if(!formValues.billing_phone){
        errors.billing_phone = "You must enter your phone number"
    }
    if(!formValues.billing_country){
        errors.billing_country = "You must select your country"
    }
    if(!formValues.billing_region){
        errors.billing_region = "You must select your region / aria"
    }
    if(!formValues.billing_city){
        errors.billing_city = "You must enter your city"
    }
    if(!formValues.billing_street_address){
        errors.billing_street_address = "You must enter your address"
    }
    if(!formValues.billing_postal_code){
        errors.billing_postal_code = "You must enter your postcode"
    }

    if(!formValues.shipping_name){
        errors.shipping_name = "You must enter your shipping first name"
    }
    if(!formValues.shipping_surname){
        errors.shipping_surname = "You must enter your shipping last name"
    }
    if(!formValues.shipping_country){
        errors.shipping_country = "You must select your shipping country"
    }
    if(!formValues.shipping_region){
        errors.shipping_region = "You must select your shipping region / aria"
    }
    if(!formValues.shipping_city){
        errors.shipping_city = "You must enter your shipping city"
    }
    if(!formValues.shipping_street_address){
        errors.shipping_street_address = "You must enter your shipping address"
    }
    if(!formValues.shipping_postal_code){
        errors.shipping_postal_code = "You must enter your shipping postcode"
    }

    return errors
}

export default reduxForm({
    form: 'checkout',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(checkoutForm)