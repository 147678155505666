import {
    ACCEPT_COOKIE
} from '../actions/types'

const initialState = {
    accept: false
}

const cookieReducer = (state = initialState, action) => {
    switch(action.type){
        case ACCEPT_COOKIE:
            return {
                accept: true
            }
        default:
            return state
    }
}

export default cookieReducer