import React from 'react'

class TemplateFourSection extends React.Component{

    render(){

        return (
            <section className="_section _section-template-4 _center">
                <div className="_box">
                    <div className="_column">
                        {this.props.children}
                    </div>
                </div>
            </section>
        )
    }
}

export default TemplateFourSection