import React from "react";
import Helmet from "react-helmet";
import shareImg from './assets/img/coralandhive-share.jpg'

class Seo extends React.Component {

    render(){

        const {title, type, description, img, keywords} = this.props

        return(
            <Helmet>
                <title>{title ? title : 'Coral & Hive - Natural Handwoven Rugs'}</title>
                <link rel="canonical" href={window.location.href} />
                <meta property="og:site_name" content="Coral &amp; Hive"/>
                <meta property="og:title" content={title ? title : 'Coral & Hive - Natural Handwoven Rugs'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content={type ? type : 'website'} />
                <meta property="og:description" content={description ? description : 'Coral & Hive is a natural, home and interior brand, producing and showcasing beautiful handwoven rugs. Based out of Cape Town, we employ some of the most talented weavers in South Africa, who are at the very heart of our business.'} />
                <meta property="og:image" content={img ? img : window.location.protocol + '//' + window.location.hostname + shareImg} />
                <meta name="twitter:title" content={title ? title : 'Coral & Hive - Natural Handwoven Rugs'} />
                <meta name="twitter:image" content={img ? img : window.location.protocol + '//' + window.location.hostname + shareImg} />
                <meta name="twitter:url" content={window.location.href} />
                <meta name="twitter:description" content={description ? description : 'Coral & Hive is a natural, home and interior brand, producing and showcasing beautiful handwoven rugs. Based out of Cape Town, we employ some of the most talented weavers in South Africa, who are at the very heart of our business.'} />
                <meta name="description" content={description ? description : 'Coral & Hive is a natural, home and interior brand, producing and showcasing beautiful handwoven rugs. Based out of Cape Town, we employ some of the most talented weavers in South Africa, who are at the very heart of our business.'} />
                <meta name="keywords" content={keywords ? keywords : 'Coralandhive, Handwoven, Rugs'} />
            </Helmet>
        )
    }
}

export default Seo