import _ from 'lodash'
import {
    FETCH_POSTS_BIN,
    RESTORE_POST,
    FORCE_DELETE_POST
} from '../actions/types'

const initialState = {}

const postsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_POSTS_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_POST:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_POST:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default postsBinReducer