import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import DashboardNav from './DashboardNav'

class AccountDashboard extends React.Component{

    render(){

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Dashboard | My Account | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/shop">Shop</Link><Divider /><Link to="/my-account/dashboard">My Account</Link><Divider />Dashboard
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="My Account" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-account'>
                  <div className='_grid'>
                    <div className='_column'>
                      <div className='_flex-panel'>
                        <DashboardNav />
                        <div className='_account-dashboard-display'>
                          <h3>Dashboard</h3>
                          <p>Welcome to your account dashboard. From here you can view your orders, update your profile and update your billing information.</p>
                          <Link to="/shop" className='_button'>Continue Shopping</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

export default AccountDashboard