import admin from '../apis/admin'

import {
  FETCH_PUBLIC_SHOP_CATEGORIES,
  FETCH_PUBLIC_SHOP_CATEGORY,
  FETCH_PUBLIC_SHOP_PRODUCTS,
  FETCH_PUBLIC_SHOP_PRODUCT,
  FETCH_PUBLIC_SHOP_PRODUCT_GALLERY,
  FETCH_PUBLIC_SHOP_PRODUCT_RELATED
} from './types'

export const fetchPublicShopCategories = () => async dispatch => {
  try {
      
    const response = await admin.get('shop/categories')

      dispatch({
          type: FETCH_PUBLIC_SHOP_CATEGORIES,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch public shop categories failed')
  }
}

export const fetchPublicShopCategory = slug => async dispatch => {
  try {
      
    const response = await admin.get(`shop/categories/${slug}`)

      dispatch({
          type: FETCH_PUBLIC_SHOP_CATEGORY,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch public shop category failed')
  }
}

export const fetchPublicShopProducts = categorySlug => async dispatch => {
  try {
      
    const response = await admin.get(`shop/categories/${categorySlug}/products`)

      dispatch({
          type: FETCH_PUBLIC_SHOP_PRODUCTS,
          payload: response.data.data
      })
      
  } catch (error) {
      console.log('fetch public shop products failed')
  }
}

export const fetchPublicShopProduct = slug => async dispatch => {
  try {
      
    const response = await admin.get(`shop/products/${slug}`)

      dispatch({
          type: FETCH_PUBLIC_SHOP_PRODUCT,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch public shop product failed')
  }
}

export const fetchPublicShopProductGallery = productSlug => async dispatch => {
  try {
      
    const response = await admin.get(`shop/products/${productSlug}/gallery`)

      dispatch({
          type: FETCH_PUBLIC_SHOP_PRODUCT_GALLERY,
          payload: response.data,
          productSlug
      })
      
  } catch (error) {
      console.log('fetch public shop product gallery failed')
  }
}

export const fetchPublicShopProductRelated = productSlug => async dispatch => {
  try {

    let path = 'shop/products/related'
      
    if(productSlug){
      path = `shop/products/${productSlug}/related`
    } 

    const response = await admin.get(path)

      dispatch({
          type: FETCH_PUBLIC_SHOP_PRODUCT_RELATED,
          payload: response.data,
          productSlug
      })
      
  } catch (error) {
      console.log('fetch public shop product related failed')
  }
}