import React from 'react'
import HeroCarousel from './HeroCarousel'

class TopHeroSection extends React.Component{
    render(){
        return (
            <section className="_section _top-hero-section">
                <HeroCarousel data={this.props.data} />
            </section>
        )
    }
}

export default TopHeroSection