import _ from 'lodash'
import {
    FETCH_SHOP_GALLERY,
    CREATE_SHOP_GALLERY_IMAGE,
    FETCH_SHOP_GALLERY_IMAGE,
    UPDATE_SHOP_GALLERY_IMAGE,
    DELETE_SHOP_GALLERY_IMAGE,
    RESTORE_SHOP_GALLERY_IMAGE
} from '../../actions/types'

const initialState = {}

const shopGalleryReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_SHOP_GALLERY:
            return {
                ...state, [action.id]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_SHOP_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case FETCH_SHOP_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case UPDATE_SHOP_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }   
        case RESTORE_SHOP_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            } 
        case DELETE_SHOP_GALLERY_IMAGE:
            return {
                ...state, [action.id]: _.omit(state[action.id], action.image_id)
            }
        default:
            return state
    }
}

export default shopGalleryReducer