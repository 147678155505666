import React from 'react'
import icon from '../../assets/img/iconmonstr-x-mark-lined.svg'

const SmallCloseIcon = ({onClick}) => {
    return(
        <button className="_small-close-icon" onClick={onClick}>
            <img className='_img' src={icon} alt="Remove Item" />
        </button>
    )
}

export default SmallCloseIcon