import {
    UPDATE_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const updateTeamMemberReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_TEAM_MEMBER:
            return {
                details: action.payload,
                success: 'Team member updated successfully',
            }
        case UPDATE_TEAM_MEMBER_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updateTeamMemberReducer