import {
  PAYMENT_SUCCEEDED,
  CHECKOUT
} from '../actions/types'

const initialState = {}

const paymentStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_SUCCEEDED:
            return action.payload  

        case CHECKOUT:
            return initialState

        default:
            return state
    }
}

export default paymentStatusReducer