import _ from 'lodash'
import {
  ADD_ITEM,
  UPDATE_QUANTITY,
  CLEAR_CART,
  REMOVE_ITEM,
  GET_COUPON,
  REMOVE_COUPON,
  UPDATE_CART,
  PAYMENT_SUCCEEDED
} from '../actions/types'
import {
    setCartExp
} from '../utils'

//initial state object before any items has been added to the cart
const initialState = {
    items: {},
    totalQuantity: 0,
    coupon: null,
    subTotalCost: 0,
    deliveryCost: 0,
    discount: 0,
    totalCost: 0,
    expired: false
}

const cartReducer = (state = initialState, action) => {

    let quantity = 0
    let diff = 0
    let price = 0
    let total = 0
    let newQuantity = 0
    let subTotalCost = 0
    let discount = 0
    let deliveryCost = 4.10
    let totalCost = 0
    let currency = 'GBP'

    const getPrice = product => {
        
        let price = 0
        
        switch (currency) {
            case 'ZAR':
                price = product.price_zar
                break
            case 'GBP':
                price = product.price_gbp
                break
            case 'USD':
                price = product.price_usd
                break
            default:
                break;
        }
        return parseInt(price).toFixed(2)
    }

    const calculateDiscount = (coupon, total) => {
        switch (coupon.type) {
            case 'fixed':
                return coupon.value
            case 'percentage':
                return Math.round((coupon.percent_off / 100) * total);
            default:
                return 0;
        }
    }

    switch(action.type){

        case UPDATE_CART:

            return action.payload

        case ADD_ITEM:

            //checks if item exists in cart state and if so get the current item quantity
            if(state.items[action.payload.id]){
                quantity = state.items[action.payload.id].quantity
            }

            newQuantity = quantity + action.qty
            price = getPrice(action.payload)
            total = newQuantity * price
            diff = action.qty * price
            subTotalCost = diff + state.subTotalCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost;
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalQuantity: state.totalQuantity + action.qty,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        id: action.payload.id,
                        name: action.payload.name,
                        slug: action.payload.slug,
                        price,
                        quantity: newQuantity,
                        preview_image: action.payload.image,
                        total
                    }
                },
                expired: false
            }
            
        case UPDATE_QUANTITY:

            //checks if item exists in cart state and if so get the current item quantity

            if(!state.items[action.id])
            return state

            quantity = state.items[action.id].quantity
            price = state.items[action.id].price
            diff = (action.qty - quantity) * price
            total = action.qty * price
            subTotalCost = diff + state.subTotalCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                totalQuantity: state.totalQuantity + action.qty - quantity,
                items: {
                    ...state.items,
                    [action.id]: {
                        id: action.id,
                        name: state.items[action.id].name,
                        slug: state.items[action.id].slug,
                        price,
                        quantity: action.qty,
                        preview_image: state.items[action.id].preview_image,
                        total
                    }
                },
                expired: false
            }

        case REMOVE_ITEM:

            if(!state.items[action.id])
            return state

            quantity = state.items[action.id].quantity
            total = state.items[action.id].total
            subTotalCost = state.subTotalCost - total
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                totalCost,
                totalQuantity: state.totalQuantity - quantity, 
                items: _.omit(state.items, action.id),
                expired: false
            }

        case GET_COUPON:

                discount = calculateDiscount(action.payload, state.subTotalCost)
                totalCost = state.subTotalCost - discount + state.deliveryCost
                if(totalCost < 0){
                    totalCost = 0
                }

                setCartExp()
    
                return{
                    ...state,
                    discount,
                    totalCost,
                    coupon: action.payload,
                    expired: false
                }
    
            case REMOVE_COUPON:

                setCartExp()
    
                return{
                    ...state,
                    discount: 0,
                    totalCost: state.subTotalCost + state.deliveryCost,
                    coupon: null,
                    expired: false
                }

        case PAYMENT_SUCCEEDED:
            
            return initialState

        case CLEAR_CART:

            if(action.expired){
                state = initialState
                return {...state, expired: true}
            }

            return initialState

        default:
            return state
    }
}

export default cartReducer