import React from 'react'
import HeaderContent from './HeaderContent'
import NavLinks from './NavLinks'
import Copyright from '../ui/Copyright'

class Nav extends React.Component {

    constructor(props){
        super(props)
        this.ref = React.createRef()        
    }

    componentDidMount(){
        document.addEventListener("click", e => this.removeClassNames(e))
    }

    componentWillUnmount(){
        document.removeEventListener("click", e => this.removeClassNames(e))
    }

    removeClassNames = e => {
        if(this.ref){
            if(this.ref.current){
                if(!this.ref.current.contains(e.target)){
                    const navLinks = document.querySelectorAll("._nav-link a")
                    for(let i = 0; i < navLinks.length; i++){
                        if(navLinks[i].nextElementSibling){
                            navLinks[i].nextElementSibling.classList.remove('_show-list')
                            navLinks[i].classList.remove('_up');   
                        }                    
                    }
                }
            }            
        }        
    }

    render(){
        return(
            <nav className="_nav" ref={this.ref}>
                <div className="_nav-header">
                    <HeaderContent fill="#C1B37F" onClick={this.props.onClick} />
                </div>
                <NavLinks onClick={this.props.onClick} />
                <Copyright />
            </nav>
        )
    }
}

export default Nav