import React from 'react'
import {Link} from 'react-router-dom'

const SmallLogo = ({fill, link}) => {
    return(
        <Link to={link ? link  : '/'} className="_small-logo">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
            viewBox="0 0 62.9 72.6" width="50">
                <g id="CoralandHive_Logo" transform="translate(-35.045 -20.784)">
                    <path fill={fill ? fill : "#fff"} id="Path_114" d="M66.5,20.8L35,38.9v36.3l31.4,18.1l31.4-18.1V38.9L66.5,20.8z M52.6,61.4l2.1-9.2l8.8,3.8
                        l-5.6,5.9L52.6,61.4z M56.8,63l-6.1,6.3l1.6-6.7L56.8,63z M36.3,44.4l17.3,7.4l-2.2,9.6L36.3,60V44.4z M60.9,25.4l1.1-0.6L74,45.2
                        l-4,4.2l-12-10.8L60.9,25.4L60.9,25.4z M56.9,37.6l-6.7-6l9.3-5.4L56.9,37.6z M96.7,68.8l-6.8-2.9l6.8,0.6V68.8z M84,62L70.8,50.2
                        l3.8-3.9L84,62z M75.5,45.3l3.5-3.7l5.5,19L75.5,45.3z M83.4,63.2l-17.8-7.6l4.3-4.5L83.4,63.2z M69.1,50.3l-4.6,4.8L55,51
                        l2.6-11.2L69.1,50.3z M64.8,56.5l17.6,7.5l-22.9-2L64.8,56.5z M89.1,68.3l7.2,6.5l-2.5,1.4L89.1,68.3z M92.7,76.8l-1.9,1.1
                        l-2.4-8.4L92.7,76.8z M89.7,67.2l7,3v3.3L89.7,67.2z M96.7,65.3l-9.8-0.9L80,40.6l6.6-6.9l10.1,5.9V65.3z M79.6,39.3l-3.3-11.5
                        l9.2,5.3L79.6,39.3z M74.9,27l-0.1,0l3.8,13.2l-3.8,4L63,24.2l3.5-2L74.9,27z M49.1,32.2l7.5,6.7l-2.7,11.6l-17.6-7.5v-3.4
                        L49.1,32.2z M36.3,61.2l14.8,1.3l-2,8.6l-6.7,7l-6.2-3.6V61.2z M48.6,73.4l-1.7,7.3l-3.4-2L48.6,73.4z M48,81.3l2.2-9.6l8.2-8.5
                        L86,65.6l3.8,12.9L66.5,92L48,81.3z"/>
                </g>
            </svg>
        </Link>
    )
}

export default SmallLogo