import React from 'react'

const HamburgerIcon = ({onClick}) => {
    return(
        <div className="_hamburger-icon" onClick={onClick}>
            <div>
                <span />
                <span />
            </div>
        </div>
    )
}

export default HamburgerIcon