import admin from '../apis/admin'

import {
  CHECKOUT,
  CHECKOUT_FAILED,
  PAYMENT_SUCCEEDED
} from './types'
import history from '../history'

export const checkout = (token = null, formValues) => async dispatch => {

  try {

      if(token){
          const config = { 
              headers: { 
                  Authorization: `Bearer ${token}` 
              } 
          };

          const response = await admin.post('checkout-logged-in', formValues, config)

          dispatch({
              type: CHECKOUT,
              payload: response.data
          })

          history.push('/checkout/payment')
        
      } else {
          const response = await admin.post('checkout', formValues, {})

          dispatch({
              type: CHECKOUT,
              payload: response.data
          })

          history.push('/checkout/payment')
    }
      
  } catch (error) {
      dispatch({
          type: CHECKOUT_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const paymentSucceeded = orderId => async dispatch => {

    try {

        const response = await admin.post(`checkout/${orderId}/complete`, {})
  
        history.push('/checkout/complete')

        dispatch({
            type: PAYMENT_SUCCEEDED,
            payload: response.data
        })
        
    } catch (error) {
        console.log('something went wrong')
    }
  }