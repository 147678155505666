import _ from 'lodash'
import {
    FETCH_GALLERY_IMAGES,
    CREATE_GALLERY_IMAGE,
    FETCH_GALLERY_IMAGE,
    UPDATE_GALLERY_IMAGE,
    DELETE_GALLERY_IMAGE
} from '../actions/types'

const initialState = {}

const galleryImagesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_GALLERY_IMAGES:
            return {
                ...state, [action.id]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case FETCH_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case UPDATE_GALLERY_IMAGE:
            return {
                ...state, [action.id]: {
                    ...state[action.id], [action.payload.id]: action.payload
                }
            }
        case DELETE_GALLERY_IMAGE:
            return {
                ...state, [action.id]: _.omit(state[action.id], action.imageId)
            }
        default:
            return state
    }
}

export default galleryImagesReducer