import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import {Link} from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'

class PageNotFound extends React.Component{
    render(){
        return(
            <>
            <ScrollToTop />
            <Layout transparent={false}>
                <TemplateOneSection>
                    <ThemeHeading>
                        <h1>404 PAGE NOT FOUND</h1>
                    </ThemeHeading>
                    <p>Sorry but the page you were looking for does not exist.</p>
                    <Link to="/" className="_button">Go To Home Page</Link>
                </TemplateOneSection>
            </Layout>
            </>
        )
    }
}

export default PageNotFound