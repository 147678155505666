import {
    CHECKOUT,
    CHECKOUT_FAILED,
    CLEAR_CART,
    PAYMENT_SUCCEEDED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKOUT:
            return action.payload

        case CHECKOUT_FAILED:
            return action.payload

        case PAYMENT_SUCCEEDED:
            return initialState
            
        case CLEAR_CART:
            return initialState
        case RESET_FORMS:
            return {
                ...state,
                errors: null,
            }    
        default:
            return state
    }
}

export default checkoutReducer