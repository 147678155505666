import {
  POPUP_NEWSLETTER_HIDE
} from '../actions/types'

const initialState = {
    accept: false
}

const newsletterPopupReducer = (state = initialState, action) => {
    switch(action.type){
        case POPUP_NEWSLETTER_HIDE:
            return {
                accept: true
            }
        default:
            return state
    }
}

export default newsletterPopupReducer