import admin from '../apis/admin'

import {
    CUSTOMER_SIGNIN,
    CUSTOMER_SIGNIN_FAILED,
    CUSTOMER_SIGNOUT,
    CUSTOMER_SIGNUP,
    CUSTOMER_SIGNUP_FAILED,
    CUSTOMER_FETCH_ACCOUNT_DETAILS,
    CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED,
    CUSTOMER_UPDATE_ACCOUNT_DETAILS,
    CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED,
    CUSTOMER_UPDATE_BILLING_DETAILS,
    CUSTOMER_UPDATE_BILLING_DETAILS_FAILED,
    CUSTOMER_UPDATE_SHIPPING_DETAILS,
    CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED,
    POPUP_SHOW,
    CUSTOMER_ORDERS,
    CUSTOMER_ORDER
} from './types'

import history from '../history'

export const customerSignIn = formValues => async dispatch => {
  try {

      const response = await admin.post('customer/login', formValues)

      dispatch({
          type: CUSTOMER_SIGNIN,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CUSTOMER_SIGNIN_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const customerSignOut = token => async dispatch => {
  try {

      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('customer/logout', {}, config)

      dispatch({
          type: CUSTOMER_SIGNOUT,
          payload: response.data
      })

      history.push('/')
      
  } catch (error) {
      dispatch({
          type: CUSTOMER_SIGNOUT,
          payload: error.response ? error.response.data : null
      })
      
      history.push('/')
  }
}

export const customerSignUp = formValues => async dispatch => {
  try {

      const response = await admin.post('customer/register', formValues)

      dispatch({
          type: CUSTOMER_SIGNUP,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CUSTOMER_SIGNUP_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchCustomerAcountDetails = token => async dispatch => {
  try {

      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('customer/details', config)

      dispatch({
          type: CUSTOMER_FETCH_ACCOUNT_DETAILS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch account details failed')
      dispatch({
            type: CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED
        })
  }
}

export const updateCustomerAccountDetails = (token, formValues) => async dispatch => {
  try {

      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('customer/details/update', formValues, config)

      dispatch({
          type: CUSTOMER_UPDATE_ACCOUNT_DETAILS,
          payload: response.data
      })

      dispatch({
        type: POPUP_SHOW,
        message: 'Account Details successfully updated'
    })
      
  } catch (error) {
      dispatch({
          type: CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const updateCustomerBillingDetails = (token, formValues) => async dispatch => {
    try {
  
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('customer/billing/update', formValues, config)
  
        dispatch({
            type: CUSTOMER_UPDATE_BILLING_DETAILS,
            payload: response.data
        })
  
        dispatch({
          type: POPUP_SHOW,
          message: 'Billing Details successfully updated'
      })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_UPDATE_BILLING_DETAILS_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const updateCustomerShippingDetails = (token, formValues) => async dispatch => {
    try {
  
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('customer/shipping/update', formValues, config)
  
        dispatch({
            type: CUSTOMER_UPDATE_SHIPPING_DETAILS,
            payload: response.data
        })
  
        dispatch({
          type: POPUP_SHOW,
          message: 'Shipping Details successfully updated'
      })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchCustomerOrders = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('customer/orders', config)

        dispatch({
            type: CUSTOMER_ORDERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch customer orders failed')
    }
}

export const fetchCustomerOrder = (token, orderId) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`customer/orders/${orderId}`, config)

        dispatch({
            type: CUSTOMER_ORDER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch customer order failed')
    }
}