import React, { useState, useEffect } from 'react'

const QuantityForm = props => {

  const [qty, setQty] = useState(props.quantity);
  const [debouncedQty, setDebouncedQty] = useState(qty);
  const [popup, setPopup] = useState(false)

  const minus = () => {
    let newQty = qty > 1 ? qty - 1 : 1
    setQty(newQty)
  }

  const add = () => {
    let newQty = qty + 1
    setQty(newQty)
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedQty(qty);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [qty]);

  useEffect(() => {
    const update = () => {
      props.onChange(props.id, qty)
    };
    if (debouncedQty) {
      if(popup){
        update();
      } else {
        setPopup(true)
      }
    }
  }, [debouncedQty]);

  return(
    <>
      <div className='_custom-quantity-input'>
        <div className='_minus' onClick={minus}>—</div>
        <div className='_display'>{qty}</div>
        <div className='_plus' onClick={add}>+</div>
      </div>
    </>
  )
}

export default QuantityForm
