import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'
import {
    Input,
    // Select
} from './fields'

class NewsletterPopupForm extends React.Component{

    state= {
        button: 'Submit'
    }

    renderErrors = () => {

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Submitting'
        })
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Submitting'){
                this.setState({
                    button: 'Submit'
                })
            }            
        }

        return(
            <form id="newsletter-subscription" className="_form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="firstname" type="text" placeholder="First Name" component={Input} />
                    <Field name="lastname" type="text" placeholder="Last Name" component={Input} />
                {/* </div>
                <div className="_form-row"> */}
                    <Field name="email" type="email" placeholder="Email Address" component={Input} />
                    <Field name="country" type="text" placeholder="Country" component={Input} />
                {/* </div>
                <div className="_form-row"> */}
                    {/* <Field name="country" placeholder="Country" component={Select}>
                        <option value="">Select country</option>
                        <option>South Africa</option>
                        <option>United Kingdom</option>
                        <option>Australia</option>
                    </Field> */}
                    <div className="_form-group">
                        <button className={`_button-submit ${this.state.button === 'Submitting' ? '_sending' : ''}`}>{this.state.button}</button> 
                    </div>                   
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.firstname){
        errors.firstname = "You have to enter your first name"
    }

    if(!formValues.lastname){
        errors.lastname = "You have to enter your last name"
    }

    if(!formValues.email){
        errors.email = "Please enter email address"
    }

    if(!formValues.country){
        errors.country = "Please enter your country"
    }

    return errors
}

export default reduxForm({
    form: 'newsletter-subscription',
    validate
})(NewsletterPopupForm)