import {
    FETCH_PUBLIC_PRODUCT_DETAILS,
} from '../actions/types'

const initialState = {}

const publicProductDetailsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PRODUCT_DETAILS:
            return {
                ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicProductDetailsReducer