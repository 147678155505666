import _ from 'lodash'
import {
    FETCH_MEDIA
} from '../actions/types'

const initialState = {}

const mediaReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_MEDIA:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        default:
            return state
    }
}

export default mediaReducer