import React from 'react'
import {Link} from 'react-router-dom'
import {
  customerSignOut
} from '../../actions/customer'
import {connect} from 'react-redux'
import history from '../../history'

class DashboardNav extends React.Component{

    signout = e => {
        e.preventDefault()
        this.props.customerSignOut(this.props.auth.token)
    }

    render(){

        if(!this.props.auth.token){
            history.push('/my-account')
        }

        return(
          <nav className='_account-dashboard-nav'>
            <ul>
              
              <li>
                <Link to="/my-account/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/my-account/orders">Orders</Link>
              </li>
              <li>
                <Link to="/my-account/billing">Billing</Link>
              </li>
              <li>
                <Link to="/my-account/shipping">Shipping</Link>
              </li>
              <li>
                <Link to="/my-account/profile">Profile</Link>
              </li>
              <li>
                <Link to="/my-account" onClick={this.signout}>Logout</Link>
              </li>
            </ul>
          </nav>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState
  }
}

export default connect(mapStateToProps, {
  customerSignOut
})(DashboardNav)