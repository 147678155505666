import React from "react";
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'

class SignInForm extends React.Component{

    renderErrors = () => {

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form className="_form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} placeholder="Enter Email / Username" />
                    <Field name="password" type="password" component={Input} placeholder="Enter Password" />
                </div>
                <div className="_form-group _center">
                    <button className="_button">Sign In</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

export default reduxForm({
    form: 'signin'
})(SignInForm)