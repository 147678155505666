import React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`_hero-next-arrow ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
}

class HeroCarousel extends React.Component {

    renderItems = () => {
        return this.props.data.map(({img, msg}, index) => {
            return(
                <div key={index}>
                    <div className="_hero-item-container" style={{
                        backgroundImage: `url(${img})`
                    }}>
                        <div className="_overlay-top" />
                        <div className="_msg">
                            <div className="_box">
                                <div className="_inner">
                                    {
                                        msg && (
                                            <>
                                                <div className="_hr" />
                                                <h2 className="_heading">{msg}</h2> 
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            )
        })
    }

    render(){

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            easing: 'ease-in-out',
            nextArrow: <SampleNextArrow />
        };

        return(
            <Slider {...settings}>
                {this.renderItems()}
            </ Slider>
        )
    }
}

export default HeroCarousel