import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import {
  BASE_PATH,
  stripePublicKey
} from '../../paths'
import {connect} from 'react-redux'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentStatus from "./StripePaymentStatus";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const stripePromise = loadStripe(stripePublicKey);

const CheckoutPaymentStatusPage  = props => {

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
      // Create PaymentIntent as soon as the page loads
      fetch(`${BASE_PATH}/api/stripe-payment-create`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          //temporarily commented out, comment back in when launching
          body: JSON.stringify({ total: props.cartState.totalCost * 100, currency: 'gbp' }),
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    },);

    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    return(
        <>
        <ScrollToTop />
        <Seo 
            title="Payment | Checkout | Coral & Hive - Natural Handwoven Rugs"
            description=""
        />
        <Layout transparent={false}>
          <ScrolledIntoView>
            <Breadcrums>
              <Link to="/">Home</Link><Divider /><Link to="/shop">Shop</Link><Divider /><Link to="/cart">Cart</Link><Divider /><Link to="/checkout">Checkout</Link><Divider />Payment
            </Breadcrums>
          </ScrolledIntoView>
          <ScrolledIntoView>
            <SimpleHeading heading="Payment" />
          </ScrolledIntoView>
          <ScrolledIntoView>
            <section className='_section _section-payment'>
              <div className='_grid'>
                <div className='_column'>
                  <div className='_panel'>
                    {clientSecret && (
                      <Elements options={options} stripe={stripePromise}>
                        <StripePaymentStatus />
                      </Elements>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </ScrolledIntoView>
        </Layout>
        </>
    )
}

const mapStateToProps = state => {
  return {
    cartState: state.cartState
  }
}

export default connect(mapStateToProps)(CheckoutPaymentStatusPage)