import React from "react";
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'
import history from '../history'

class PrivateRoute extends React.Component{
    render(){

        if(!this.props.auth.token) {history.push('/login')}

        return(
            <Route exact path={this.props.path} component={this.props.component} />
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.authState
    }
}

export default connect(mapStateToProps)(PrivateRoute)