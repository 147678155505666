import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select
} from '../forms/fields'

import Recaptcha from 'react-recaptcha'

class BillingUpdateForm extends React.Component {

    state= {
        capchaLoaded: null,
        capchaVerified: null,
        country: this.props.initialValues ? this.props.initialValues.billing_country : null
    }

    setCountry = e => {
        this.setState({country: e.target.value})
    }

    renderRegions = () => {

        switch (this.state.country) {
            case 'United Kingdom':
                return (
                    <div className="_form-row">
                        <Field 
                            name="billing_region" 
                            label="Region*" 
                            component={Select} 
                        >
                            <option value="">Please select a region</option>
                            <option>England</option>
                            <option>Scotland</option>
                            <option>Wales</option>
                            <option>Northern Ireland</option>
                        </Field>
                    </div>
                )
            case 'USA':
                return (
                    <div className="_form-row">
                        <Field 
                            name="billing_region" 
                            label="State*" 
                            component={Select} 
                        >
                            <option value="">Please select a state</option>
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Delaware</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option> 
                            <option>New Jersey</option> 
                            <option>New Mexico</option> 
                            <option>New York</option> 
                            <option>North Carolina</option> 
                            <option>North Dakota</option> 
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option> 
                            <option>South Carolina</option> 
                            <option>South Dakota</option> 
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option> 
                            <option>Wisconsin</option>
                            <option>Wyoming</option>
                        </Field>
                    </div>
                )
            case 'South Africa':
                return (
                    <div className="_form-row">
                        <Field 
                            name="billing_region" 
                            label="Province*" 
                            component={Select} 
                        >
                            <option value="">Please select a province</option>
                            <option>Western Cape</option>
                            <option>Garden Route</option>
                            <option>Eastern Cape</option>
                            <option>Free State</option>
                            <option>Gauteng</option>
                            <option>KwaZulu-Natal</option>
                            <option>Limpopo</option>
                            <option>Mpumalanga</option>
                            <option>Northern Cape</option>
                            <option>North West</option>
                        </Field>
                    </div>
                    )
            default:
                return (
                    <div className="_form-row">
                        <Field name="billing_region" type="text" component={Input} label="Enter region or area*" />
                    </div>
                )
        }
    }

    renderErrors = () => {
        if(!this.props.errors){
            return <></>
        }

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {   
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
      console.log('recapcha has loaded')
      this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {
      if(!this.state.capchaLoaded)
      return

      if(!this.state.capchaVerified)
      console.log('Robot filter denied')

      else 
      this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="billing_phone" type="text" component={Input} label="Phone Number*" />
                </div>

                <div className="_form-row">
                    <Field 
                        name={`billing_country${this.state.country === 'Other' ? '_other' : ''}`}
                        label="Select Country*" 
                        component={Select} 
                        onChange={this.setCountry}
                    >
                        <option value="">Please select a country</option>
                        <option>United Kingdom</option>
                        <option>USA</option>
                        <option>South Africa</option>
                        <option>Other</option>
                    </Field>
                </div>

                {this.state.country === 'Other' && (
                    <div className="_form-row">
                        <Field name="billing_country" type="text" component={Input} label="Please type country here*" />
                    </div>
                )}

                {this.renderRegions()}

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="billing_city" 
                        label="City / Town*" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field name="billing_street_address" type="text" component={Input} label="Address*" />
                </div>

                <div className="_form-row">
                    <Field name="billing_postal_code" type="text" component={Input} label="Postcode*" />
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Lf25pAfAAAAAGjQv-ug0i8FQTMH7451quTi_sV7"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                <br />
                 
                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>Update</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    return errors
}

export default reduxForm({
    form: 'billingupdate',
    validate
})(BillingUpdateForm)