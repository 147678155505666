// couponReducer
import {
  GET_COUPON,
  GET_COUPON_FAILED,
  REMOVE_COUPON,
  CLEAR_CART,
  RESET_FORMS,
  PAYMENT_SUCCEEDED
} from '../actions/types'

const initialState = {
  success: null,
  details: null,
  errors: null
}

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
      case GET_COUPON:
        return {
            success: true,
            details: action.payload
        }
      case GET_COUPON_FAILED:
        return {
          success: null,
          errors: action.payload
        } 
        
      case REMOVE_COUPON:
        return initialState
      
        case RESET_FORMS:
          return {
            ...state,
            success: null,
            errors: null
          }
          
        case PAYMENT_SUCCEEDED:
          return initialState
        
        case CLEAR_CART:
          return initialState
      default:
        return state
  }
}

export default couponReducer