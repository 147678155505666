import CartIcon from './CartIcon'
import ProfileIcon from './ProfileIcon'

const CartProfileIcons = props => {
  return (
    <div className='_cart-and-profile-icons'>
        <CartIcon />
        <ProfileIcon />
    </div>
  )
}

export default CartProfileIcons