import {
    UPDATE_PRODUCT_GALLERY_IMAGE,
    UPDATE_PRODUCT_GALLERY_IMAGE_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const updateProductGalleryImageReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_PRODUCT_GALLERY_IMAGE:
            return {
                details: action.payload,
                success: 'Image updated successfully',
            }
        case UPDATE_PRODUCT_GALLERY_IMAGE_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updateProductGalleryImageReducer