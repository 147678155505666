import React from 'react'
import ScrolledIntoView from '../../ScrolledIntoView'
import SplitColumnImage from './SplitColumnImage'

class SplitColumnSection extends React.Component{

    renderRows(){
        if(this.props.data){
            return this.props.data.map(({img, img2, content}, index) => {
                return(
                    <div className={`_fluid ${this.props.odd ? '_odd' : '_even'}`} key={index}>
                        <SplitColumnImage img={img} img2={img2} />
                        <div className="_column">
                            <ScrolledIntoView>
                                <div className="_panel">
                                    {content}
                                </div>
                            </ScrolledIntoView>
                        </div>                    
                    </div>
                )
            })
        }
    }

    render(){
        return (
            <section className="_section _section-template-2">
                {this.renderRows()}
            </section>
        )
    }
}

export default SplitColumnSection