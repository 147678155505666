export const SEND_CONTACT_EMAIL = 'SEND_CONTACT_EMAIL'
export const SEND_CONTACT_EMAIL_FAILED = 'SEND_CONTACT_EMAIL_FAILED'
export const SEND_CONTACT_MAIL_RESET = 'SEND_CONTACT_MAIL_RESET'

export const NEWSLETTER_SUBSCRIPTION = 'NEWSLETTER_SUBSCRIPTION'
export const NEWSLETTER_SUBSCRIPTION_FAILED = 'NEWSLETTER_SUBSCRIPTION_FAILED'
export const NEWSLETTER_SUBSCRIPTION_RESET = 'NEWSLETTER_SUBSCRIPTION_RESET'

export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

export const RESET_FORMS = 'RESET_FORMS'

export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS_FAILES = 'UPDATE_ACCOUNT_DETAILS_FAILES'

export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'
export const RESTORE_USER = 'RESTORE_USER'
export const FETCH_USERS_BIN = 'FETCH_USERS_BIN'
export const FORCE_DELETE_USER = 'FORCE_DELETE_USER'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const FETCH_PRODUCTS_BIN = 'FETCH_PRODUCTS_BIN'
export const FORCE_DELETE_PRODUCT = 'FORCE_DELETE_PRODUCTS'

export const FETCH_PRODUCT_GALLERY = 'FETCH_PRODUCT_GALLERY'
export const CREATE_PRODUCT_GALLERY_IMAGE = 'CREATE_PRODUCT_GALLERY_IMAGE'
export const CREATE_PRODUCT_GALLERY_IMAGE_FAILED = 'CREATE_PRODUCT_GALLERY_IMAGE_FAILED'
export const FETCH_PRODUCT_GALLERY_IMAGE = 'FETCH_PRODUCT_GALLERY_IMAGE'
export const UPDATE_PRODUCT_GALLERY_IMAGE = 'UPDATE_PRODUCT_GALLERY_IMAGE'
export const UPDATE_PRODUCT_GALLERY_IMAGE_FAILED = 'UPDATE_PRODUCT_GALLERY_IMAGE_FAILED'
export const DELETE_PRODUCT_GALLERY_IMAGE = 'DELETE_PRODUCT_GALLERY_IMAGE'
export const RESTORE_PRODUCT_GALLERY_IMAGE = 'RESTORE_PRODUCT_GALLERY_IMAGE'
export const FETCH_PRODUCT_GALLERY_BIN = 'FETCH_PRODUCT_GALLERY_BIN'
export const FORCE_DELETE_PRODUCT_GALLERY_IMAGE = 'FORCE_DELETE_PRODUCT_GALLERY_IMAGE'

export const FETCH_POSTS = 'FETCH_POSTS'
export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED'
export const FETCH_POST = 'FETCH_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_FAILED = 'UPDATE_POST_FAILED'
export const DELETE_POST = 'DELETE_POST'
export const RESTORE_POST = 'RESTORE_POST'
export const FETCH_POSTS_BIN = 'FETCH_POSTS_BIN'
export const FORCE_DELETE_POST = 'FORCE_DELETE_POSTS'

export const FETCH_TEAM = 'FETCH_TEAM'
export const CREATE_TEAM_MEMBER = 'CREATE_TEAM_MEMBER'
export const CREATE_TEAM_MEMBER_FAILED = 'CREATE_TEAM_MEMBER_FAILED'
export const FETCH_TEAM_MEMBER = 'FETCH_TEAM_MEMBER'
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER'
export const UPDATE_TEAM_MEMBER_FAILED = 'UPDATE_TEAM_MEMBER_FAILED'
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER'
export const RESTORE_TEAM_MEMBER = 'RESTORE_TEAM_MEMBER'
export const FETCH_TEAM_BIN = 'FETCH_TEAM_BIN'
export const FORCE_DELETE_TEAM_MEMBER = 'FORCE_DELETE_TEAM_MEMBER'

export const FETCH_MEDIA = 'FETCH_MEDIA'

export const FETCH_PUBLIC_PRODUCTS = 'FETCH_PUBLIC_PRODUCTS'
export const FETCH_PUBLIC_PRODUCT_DETAILS = 'FETCH_PUBLIC_PRODUCT_DETAILS'

export const FETCH_PUBLIC_POSTS = 'FETCH_PUBLIC_POSTS'
export const FETCH_PUBLIC_POST_DETAILS = 'FETCH_PUBLIC_POST_DETAILS'

export const ACCEPT_COOKIE = 'ACCEPT_COOKIE'

export const FETCH_GALLERIES = 'FETCH_GALLERIES'
export const FETCH_GALLERY = 'FETCH_GALLERY'
export const FETCH_GALLERY_IMAGES = 'FETCH_GALLERY_IMAGES'
export const CREATE_GALLERY_IMAGE = 'CREATE_GALLERY_IMAGE'
export const CREATE_GALLERY_IMAGE_FAILED = 'CREATE_GALLERY_IMAGE_FAILED'
export const FETCH_GALLERY_IMAGE = 'FETCH_GALLERY_IMAGE'
export const UPDATE_GALLERY_IMAGE = 'UPDATE_GALLERY_IMAGE'
export const UPDATE_GALLERY_IMAGE_FAILED = 'UPDATE_GALLERY_IMAGE_FAILED'
export const DELETE_GALLERY_IMAGE = 'DELETE_GALLERY_IMAGE'

export const FETCH_PUBLIC_GALLERY_IMAGES = 'FETCH_PUBLIC_GALLERY_IMAGES'

export const FETCH_PUBLIC_TEAM = 'FETCH_PUBLIC_TEAM'

export const POPUP_NEWSLETTER_HIDE = 'POPUP_NEWSLETTER_HIDE'

//ECOMMERCE

export const SET_CURRENCY = 'SET_CURRENCY'

export const FETCH_SHOPS = 'FETCH_SHOPS'
export const CREATE_SHOP = 'CREATE_SHOP'
export const CREATE_SHOP_FAILED = 'CREATE_SHOP_FAILED'
export const FETCH_SHOP = 'FETCH_SHOP'
export const UPDATE_SHOP = 'UPDATE_SHOP'
export const UPDATE_SHOP_FAILED = 'UPDATE_SHOP_FAILED'
export const DELETE_SHOP = 'DELETE_SHOP'
export const RESTORE_SHOP = 'RESTORE_SHOP'
export const FETCH_SHOPS_BIN = 'FETCH_SHOPS_BIN'
export const FORCE_DELETE_SHOP = 'FORCE_DELETE_SHOPS'

export const FETCH_SHOP_GALLERY = 'FETCH_SHOP_GALLERY'
export const CREATE_SHOP_GALLERY_IMAGE = 'CREATE_SHOP_GALLERY_IMAGE'
export const CREATE_SHOP_GALLERY_IMAGE_FAILED = 'CREATE_SHOP_GALLERY_IMAGE_FAILED'
export const FETCH_SHOP_GALLERY_IMAGE = 'FETCH_SHOP_GALLERY_IMAGE'
export const UPDATE_SHOP_GALLERY_IMAGE = 'UPDATE_SHOP_GALLERY_IMAGE'
export const UPDATE_SHOP_GALLERY_IMAGE_FAILED = 'UPDATE_SHOP_GALLERY_IMAGE_FAILED'
export const DELETE_SHOP_GALLERY_IMAGE = 'DELETE_SHOP_GALLERY_IMAGE'
export const RESTORE_SHOP_GALLERY_IMAGE = 'RESTORE_SHOP_GALLERY_IMAGE'
export const FETCH_SHOP_GALLERY_BIN = 'FETCH_SHOP_GALLERY_BIN'
export const FORCE_DELETE_SHOP_GALLERY_IMAGE = 'FORCE_DELETE_SHOP_GALLERY_IMAGE'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const RESTORE_CATEGORY = 'RESTORE_CATEGORY'
export const FETCH_CATEGORIES_BIN = 'FETCH_CATEGORIES_BIN'
export const FORCE_DELETE_CATEGORY = 'FORCE_DELETE_CATEGORIES'

export const FETCH_COUPONS = 'FETCH_COUPONS'
export const CREATE_COUPON = 'CREATE_COUPON'
export const CREATE_COUPON_FAILED = 'CREATE_COUPON_FAILED'
export const FETCH_COUPON = 'FETCH_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED'
export const DELETE_COUPON = 'DELETE_COUPON'
export const RESTORE_COUPON = 'RESTORE_COUPON'
export const FETCH_COUPONS_BIN = 'FETCH_COUPONS_BIN'
export const FORCE_DELETE_COUPON = 'FORCE_DELETE_COUPONS'

export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'

//public shop
export const FETCH_PUBLIC_SHOP_CATEGORIES = 'FETCH_PUBLIC_SHOP_CATEGORIES'
export const FETCH_PUBLIC_SHOP_CATEGORY = 'FETCH_PUBLIC_SHOP_CATEGORY'
export const FETCH_PUBLIC_SHOP_PRODUCTS = 'FETCH_PUBLIC_SHOP_PRODUCTS'
export const FETCH_PUBLIC_SHOP_PRODUCT = 'FETCH_PUBLIC_SHOP_PRODUCT'
export const FETCH_PUBLIC_SHOP_PRODUCT_GALLERY = 'FETCH_PUBLIC_SHOP_PRODUCT_GALLERY'
export const FETCH_PUBLIC_SHOP_PRODUCT_RELATED = 'FETCH_PUBLIC_SHOP_PRODUCT_RELATED'

//cart
export const ADD_ITEM = 'ADD_ITEM'
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_CART = 'UPDATE_CART'

export const POPUP_SHOW = 'POPUP_SHOW'
export const POPUP_HIDE = 'POPUP_HIDE'

//customer
export const CUSTOMER_SIGNIN = 'CUSTOMER_SIGNIN'
export const CUSTOMER_SIGNIN_FAILED = 'CUSTOMER_SIGNIN_FAILED'
export const CUSTOMER_SIGNOUT = 'CUSTOMER_SIGNOUT'
export const CUSTOMER_SIGNUP = 'CUSTOMER_SIGNUP'
export const CUSTOMER_SIGNUP_FAILED = 'CUSTOMER_SIGNUP_FAILED'
export const CUSTOMER_FETCH_ACCOUNT_DETAILS = 'CUSTOMER_FETCH_ACCOUNT_DETAILS'
export const CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED = 'CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED'
export const CUSTOMER_UPDATE_ACCOUNT_DETAILS = 'CUSTOMER_UPDATE_ACCOUNT_DETAILS'
export const CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED = 'CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED'
export const CUSTOMER_UPDATE_BILLING_DETAILS = 'CUSTOMER_UPDATE_BILLING_DETAILS'
export const CUSTOMER_UPDATE_BILLING_DETAILS_FAILED = 'CUSTOMER_UPDATE_BILLING_DETAILS_FAILED'
export const CUSTOMER_UPDATE_SHIPPING_DETAILS = 'CUSTOMER_UPDATE_SHIPPING_DETAILS'
export const CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED = 'CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED'
export const CUSTOMER_ORDERS = 'CUSTOMER_ORDERS'
export const CUSTOMER_ORDER = 'CUSTOMER_ORDER'

//checkout
export const CHECKOUT = 'CHECKOUT'
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED'
export const PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED'

//coupon
export const GET_COUPON = 'GET_COUPON'
export const GET_COUPON_FAILED = 'GET_COUPON_FAILED'
export const REMOVE_COUPON = 'REMOVE_COUPON'

//password forgot
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'
export const FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP'
export const FORGOT_PASSWORD_OTP_FAILED = 'FORGOT_PASSWORD_OTP_FAILED'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'
export const FORGOT_PASSWORD_RESET_FAILED = 'FORGOT_PASSWORD_RESET_FAILED'