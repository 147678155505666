import React from 'react'

class ScrolledIntoView extends React.Component {

    constructor(props) {
        super(props)
        this.element = React.createRef()
        this.state = {
            show: false
        }
    }

    componentDidMount(){
        const time = this.props.delay ? this.props.delay : 410
        setTimeout(() => {
            this.animate()
        }, time);
        
        document.addEventListener('scroll', () => {
            if(!this.state.show){
                this.animate()
            }            
        }, false);
                
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', () => {
            this.animate()
        }, false); 
    }

    animate = () => {
        var element = this.element.current;
        if(element){
            // var elementHeight = element.clientHeight;
            // check if element is in view
            // get window height
            var windowHeight = window.innerHeight;
            // get number of pixels that the document is scrolled
            var scrollY = window.scrollY || window.pageYOffset;
            
            // get current scroll position (distance from the top of the page to the bottom of the current viewport)
            var scrollPosition = scrollY + windowHeight;
            // get element position (distance from the top of the page to the bottom of the element)
            var elementPosition = element.getBoundingClientRect().top + scrollY;
            
            // is scroll position greater than element position? (is element in view?)
            if (scrollPosition > elementPosition) {
                this.setState({
                    show: true
                })
            } 
        }        
    }

    render(){
        return(
            <div className={`_animate-in ${this.state.show ? '_animate-in-enter' : ''} ${this.props.ltr ? '_left-to-right' : ''} ${this.props.rtl ? '_right-to-left' : ''} ${this.props.fade ? '_fade' : ''}`} ref={this.element} >
                {this.props.children}
            </div>
        )
    }
}

export default ScrolledIntoView