import React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BASE_PATH} from '../../paths'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`_gallery-next-arrow ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`_gallery-prev-arrow ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
}

class GalleryCarousel extends React.Component {

    renderItems = () => {
        return this.props.data.map(({image, caption}, index) => {
            return(
                <div key={index}>
                    <div className="_gallery-item">
                        <img src={BASE_PATH+image} alt={caption ? caption : ""} /> 
                        {caption && <div className='_caption'>{caption}</div>}
                    </div>                   
                </div>
            )
        })
    }

    render(){

        const settings = {
            centerMode: true,
            variableWidth: true,
            infinite: true,
            slidesToShow: 1,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5000,
            easing: 'ease-in-out',
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                      adaptiveHeight: true
                    }
                }
            ]
        };

        return(
            <Slider {...settings}>
                {this.renderItems()}
            </ Slider>
        )
    }
}

export default GalleryCarousel