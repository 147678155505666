import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import {Link} from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'

class ContactThankyou extends React.Component{
    render(){
        return(
            <>
            <ScrollToTop />
            <Layout transparent={false}>
                <TemplateOneSection>
                    <ThemeHeading>
                        <h1>MESSAGE SENT</h1>
                    </ThemeHeading>
                    <p>Thank you for contacting us. A consultant will be in touch with you shortly.</p>
                    <Link to="/" className="_button">Go To Home Page</Link>
                </TemplateOneSection>
            </Layout>
            </>
        )
    }
}

export default ContactThankyou