import {
    CUSTOMER_FETCH_ACCOUNT_DETAILS,
    CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED,
    CUSTOMER_UPDATE_ACCOUNT_DETAILS,
    CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED,
    CUSTOMER_UPDATE_BILLING_DETAILS,
    CUSTOMER_UPDATE_BILLING_DETAILS_FAILED,
    CUSTOMER_UPDATE_SHIPPING_DETAILS,
    CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED,
    CUSTOMER_SIGNOUT,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const customerProfileReducer = (state = initialState, action) => {
    switch(action.type){
        case CUSTOMER_FETCH_ACCOUNT_DETAILS:
            return {
                details: action.payload
            }
        case CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED:
            return initialState  
        case CUSTOMER_UPDATE_ACCOUNT_DETAILS:
            return {
                details: action.payload,
                success: 'Profile updated successfully',
                errors: null
            }
        case CUSTOMER_UPDATE_ACCOUNT_DETAILS_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case CUSTOMER_UPDATE_BILLING_DETAILS:
            return {
                details: action.payload,
                success: 'Billing Details updated successfully',
                errors: null
            }
        case CUSTOMER_UPDATE_BILLING_DETAILS_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case CUSTOMER_UPDATE_SHIPPING_DETAILS:
            return {
                details: action.payload,
                success: 'Shipping Details updated successfully',
                errors: null
            }
        case CUSTOMER_UPDATE_SHIPPING_DETAILS_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
          return {
              ...state,
              errors: null,
              success: null
          }
        case CUSTOMER_SIGNOUT:
            return initialState
        default:
            return state
    }
}

export default customerProfileReducer