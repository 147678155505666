import {
    UPDATE_SHOP_GALLERY_IMAGE,
    UPDATE_SHOP_GALLERY_IMAGE_FAILED,
    RESET_FORMS
} from '../../actions/types'

const initialState = {}

const updateShopGalleryImageReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_SHOP_GALLERY_IMAGE:
            return {
                details: action.payload,
                success: 'Image updated successfully',
            }
        case UPDATE_SHOP_GALLERY_IMAGE_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updateShopGalleryImageReducer