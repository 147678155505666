import React from 'react'
import HeaderContent from './HeaderContent'

class HeaderWhite extends React.Component {
    render(){
        return(
            <header className={`_header _header-white ${this.props.className ? this.props.className : '' }`}>
                <HeaderContent fill="#C1B37F" hamburgerIcon={true} onClick={this.props.onClick} smallLogo={true} />
            </header>
        )
    }
}

export default HeaderWhite