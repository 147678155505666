import {
    FETCH_PUBLIC_GALLERY_IMAGES,
} from '../actions/types'

const initialState = {}

const publicGalleryImagesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_GALLERY_IMAGES:
            return {
                ...state, [action.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicGalleryImagesReducer