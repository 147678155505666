import admin from '../apis/admin'

import {
  ADD_ITEM,
  CLEAR_CART,
  UPDATE_QUANTITY,
  REMOVE_ITEM,
  POPUP_SHOW,
  UPDATE_CART
} from './types'
import {Link} from 'react-router-dom'

import {
    clearCartIfExpired
} from '../utils'
import {store} from '../store'


export const updateCart = () => async dispatch => {

    const cart = store.getState('cartState').cartState 

    const response = await admin.post('cart/update', cart)
  
    dispatch({
        type: UPDATE_CART,
        payload: response.data
    })
  }


export const addItem = (slug, qty) => async dispatch => {
  const response = await admin.get(`shop/products/${slug}`)

  clearCartIfExpired()

  dispatch({
      type: ADD_ITEM,
      payload: response.data,
      qty
  })

  dispatch({
      type: POPUP_SHOW,
      message: 'Item Added to Cart',
      buttonText: 'Continue Shopping',
      children: (
        <Link className='_button' to="/cart">
            Go To Cart
        </Link>
      )
  })
}

export const updateQuantity = (id, qty) => {

    clearCartIfExpired()

    return (dispatch) => {
        dispatch({
            type: UPDATE_QUANTITY,
            id,
            qty
        })

        dispatch({
            type: POPUP_SHOW,
            message: 'Item Quantity Updated'
        })
    }
}

export const removeItem = (id) => {

    clearCartIfExpired()

    return (dispatch) => {
        dispatch({
            type: REMOVE_ITEM,
            id
        })

        dispatch({
            type: POPUP_SHOW,
            message: 'Item Removed'
        })
    }
}

export const clearCart = () => {
  return (dispatch) => {
      dispatch({
          type: CLEAR_CART
      })

      dispatch({
          type: POPUP_SHOW,
          message: 'Cart Cleared'
      })
  }
}

