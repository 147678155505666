import React from 'react'
import Layout from '../layout/Layout'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {Link} from 'react-router-dom'
import Breadcrums from '../ui/Breadcrums'
import Divider from '../ui/Divider'
import SimpleHeading from '../ui/SimpleHeading'
import AccountLoginForm from './AccountLoginForm'
import AccountRegisterForm from './AccountRegisterForm'
import {connect} from 'react-redux'
import {
  customerSignIn,
  customerSignUp
} from '../../actions/customer'
import {
  resetForms
} from '../../actions'
import history from '../../history'

class LoginRegisterPage extends React.Component{

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSignin = formValues => {
      this.props.customerSignIn(formValues)
    }

    onSignup = formValues => {
      this.props.customerSignUp(formValues)
    }

    render(){

        if(this.props.auth.token){
            history.push('/my-account/dashboard')
        }

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="My Account | Coral & Hive - Natural Handwoven Rugs"
                description=""
            />
            <Layout transparent={false}>
              <ScrolledIntoView>
                <Breadcrums>
                  <Link to="/">Home</Link><Divider />My Account
                </Breadcrums>
              </ScrolledIntoView>
              <ScrolledIntoView>
                <SimpleHeading heading="My Account" />
              </ScrolledIntoView>
              <ScrolledIntoView>
                <section className='_section _section-account'>
                  <div className='_grid _grid-2'>
                    <div className='_column'>
                      <h3>Log In</h3>
                      <AccountLoginForm 
                        onSubmit={this.onSignin}
                        errors={this.props.auth.errors ? this.props.auth.errors : null} 
                      />
                      <p className='_forgot-password' style={{textDecoration: 'underline'}}><Link to="/forgot-password">Forgot my password?</Link></p>
                    </div>
                    <div className='_column'>
                      <h3>Register</h3>
                      <AccountRegisterForm 
                        onSubmit={this.onSignup} 
                        errors={this.props.auth.errors ? this.props.auth.errors : null}
                      />
                    </div>
                  </div>
                </section>
              </ScrolledIntoView>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
  return {
    auth: state.customerAuthState
  }
}

export default connect(mapStateToProps, {
  customerSignIn,
  customerSignUp,
  resetForms
})(LoginRegisterPage)