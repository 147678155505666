import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import img from '../../assets/img/collection-of-work.jpg'
import GetInTouch from '../ui/GetInTouch'
import MasonaryGridBlog from '../ui/MasonaryGridBlog'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
    fetchPublicGalleryImages
} from '../../actions'

class Gallery extends React.Component{

    componentDidMount(){
        this.props.fetchPublicGalleryImages('collection-of-work')
    }

    render(){

        return(
            <>
                <ScrollToTop />
                <Seo 
                    title="Gallery | Coral & Hive - Natural Handwoven Rugs"
                    image={window.location.protocol + '//' + window.location.hostname + img}
                />
                <Layout transparent={false}>
                    <TemplateOneSection className="_padding-bottom-none">
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>COLLECTION OF WORK</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    <TemplateOneSection fluid className="_padding-bottom-none _widescreen">
                        <div className="_background-image-container" style={{
                            backgroundImage: `url(${img})`,
                            paddingBottom: '50%'
                        }} />
                    </TemplateOneSection>
                    <section className="_section _section-template-1 _center">
                        <div className="_box">
                            <div className="_inner">
                                {this.props.data && <MasonaryGridBlog data={this.props.data} />}
                            </div>                        
                        </div>
                    </section>
                    <GetInTouch />
                </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.publicGalleryImagesState['collection-of-work']
    }
}

export default connect(mapStateToProps, {
    fetchPublicGalleryImages
})(Gallery)