import {
    CUSTOMER_SIGNIN,
    CUSTOMER_SIGNIN_FAILED,
    CUSTOMER_SIGNOUT,
    CUSTOMER_SIGNUP,
    CUSTOMER_SIGNUP_FAILED,
    RESET_FORMS,
    CHECKOUT,
    CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED
} from '../actions/types'

const initialState = {}

const customerAuthReducer = (state = initialState, action) => {
    switch(action.type){
        case CUSTOMER_SIGNIN:
            return {
                token: action.payload
            }
        case CUSTOMER_SIGNIN_FAILED:
            return {
                errors: action.payload
            }
        case CUSTOMER_SIGNUP:
            return {
                token: action.payload
            }
        case CUSTOMER_SIGNUP_FAILED:
            return {
                errors: action.payload
            }
        case CHECKOUT:
            if(action.payload.token){
                return {
                    ...state,
                    token: action.payload.token
                }
            }
        case RESET_FORMS:
            return {
                ...state,
                errors: null
            }
        case CUSTOMER_SIGNOUT:
            return initialState
        case CUSTOMER_FETCH_ACCOUNT_DETAILS_FAILED:
            return initialState
        default:
            return state
    }
}

export default customerAuthReducer