import React from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'

class TermsAndConditions extends React.Component{
    render(){
        
        return(
            <>
                <ScrollToTop />
                <Seo 
                    title="Terms & Conditions | Coral & Hive - Natural Handwoven Rugs"
                />
            
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>TERMS & CONDITIONS</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <div className='_terms-copy'>
                                <p><strong>General:</strong></p>
                                <p>
                                    <span>1.</span>
                                    <span>These terms are intended to neither counter nor reduce any rights you have as a consumer. In all cases, any disputes which may arise will be subject to the prevailing laws of the Republic of South Africa.</span>
                                </p>
                                <p>
                                    <span>2.</span>
                                    <span>Coral & Hive is not liable for any injury, loss or damage suffered by our customers through the use of its products and/or services save for in cases of gross negligence and/or willful acts committed by its employees.</span>
                                </p>
                                <p>
                                    <span>3.</span>
                                    <span>Coral & Hive respects the privacy of its customers and will not unnecessarily contact its customers without permission which can be revoked at any time.</span>
                                </p> 
                                <p><strong>Hand crafted rugs:</strong></p>
                                <p>
                                    <span>4.</span>
                                    <span>Each Coral & Hive rug is proudly woven by hand using natural fibers. The use of natural fibers and being hand woven sometimes means that sometimes there are minor irregularities in colour or size. Due to natural variations Coral & Hive cannot guarantee the exact colour or precise size of the finished rug when ordered. These small imperfections and irregularities are not flaws but evidence of the rug’s hand crafted origins.</span>
                                </p>
                                <p>
                                    <span>5.</span>
                                    <span>The natural fibers used in Coral & Hive rugs may shed initially but will settle over time.</span>
                                </p>
                                <p>
                                    <span>6.</span>
                                    <span>All rugs are inspected for defects and damage by Coral & Hive prior dispatch for delivery.</span>
                                </p>
                                <p>
                                    <span>7.</span>
                                    <span>Coral & Hive rugs are made with natural materials. Care and cleaning of rugs should be done by a reputable natural rug cleaning specialist. Rugs should be vacuumed with flat head attachment and not bristles.</span>
                                </p>
                                <p>
                                    <span>8.</span>
                                    <span>Colouring of rug fibers is done in accordance with the ARS colour standard.</span>
                                </p>
                                <p><strong>Quotations and orders:</strong></p>
                                <p>
                                    <span>9.</span>
                                    <span>Coral & Hive is happy to provide quotations to its customers without any obligation or charge. Quotations are based on customer requests and location.</span>
                                </p>
                                <p>
                                    <span>10.</span>
                                    <span>Coral & Hive requires payment of the full purchase price (100%) as quoted before commencing production and design of rugs for its customers.</span>
                                </p>
                                <p>
                                    <span>11.</span>
                                    <span>
                                        All payments should be made in to Coral & Hive’s account with the following details:<br /><br />
                                        <strong>Bank: Investec Bank Limited</strong><br /><br />
                                        <strong>Account number: 100 128 703 44</strong><br /><br />
                                        <strong>Branch code: 580 105</strong><br /><br />
                                        <strong>Swift code: IVESZAJJ</strong><br /><br />
                                        <i>Please use your invoice number as a reference when making payment and email proof of payment.</i>

                                    </span>
                                </p>
                                <p>
                                    <span>12.</span>
                                    <span>All rugs are made to order and payment is due on the date of order. Coral & Hive cannot guarantee manufacture of a rug or that certain fibers and/or materials will be available until payment reflects in its bank account.</span>
                                </p>
                                <p><strong>Cancellations and returns:</strong></p>
                                <p>
                                    <span>13.</span>
                                    <span>All fibers used by Coral & Hive for each rug are sourced once an order from the customer is confirmed. Regrettably therefore, Coral & Hive does not accept cancellations after 24 hours from time of order.</span>
                                </p>
                                <p>
                                    <span>14.</span>
                                    <span>Since the rugs are made to order, Coral & Hive only accepts returns of rugs that are damaged by Coral & Hive or incorrectly delivered. Coral & Hive will refund the purchase price of rugs that have been damaged or incorrectly delivered provided that the rug is returned to Coral & Hive (at the customer’s expense) and the rug is in the same condition it was when received by the customer. All returns must be made within 30 days from the date the customer received the rug.</span>
                                </p>
                                <p>
                                    <span>15.</span>
                                    <span>Any rugs returned to Coral & Hive must be returned in their original wrapping or equivalent high quality waterproof wrapping. Coral & Hive will not be responsible or liable for returned rugs that have been damaged during delivery to or from the customer.</span>
                                </p>
                                <p><strong>International orders:</strong></p>
                                <p>
                                    <span>19.</span>
                                    <span>Coral & Hive does its best to ensure that orders reach its customers  as soon as possible and as efficiently as possible, however Coral & Hive is not responsible for unreceived orders. International customers are encouraged to arrange for insurance of their orders.</span>
                                </p>
                                <p>
                                    <span>20.</span>
                                    <span>Certain national laws may prohibit the importation of certain goods or require import permits, customs charges, insurance etc. before allowing delivery to customers. The customer is responsible for ensuring compliance with local laws, customs charges, obtaining the necessary permits or anything else required for delivery to a foreign country.</span>
                                </p>
                                <p><strong>Intellectual property:</strong></p>
                                <p>
                                    <span>21</span>
                                    <span>All rights, including copyright, trade mark and other intellectual property rights embodied in any logos, text, images, video, audio or other material on Coral & Hive’s website, communication, order forms, invoices etc or at its premises (including patterns, designs etc) are owned by and licensed to Coral & Hive. Unauthorized use, reproduction, modification and/or distribution of the content thereof is strictly prohibited and constitutes an unlawful infringement of intellectual property rights.</span>
                                </p>
                                
                            </div>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                </Layout>
            </>
        )
    }
}

export default TermsAndConditions